import {
    AppBar, Grid, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, makeStyles, Popover, Toolbar,
    Typography
} from '@material-ui/core';
import { ArrowDropDownRounded, ExitToApp } from '@material-ui/icons';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { Avatar, Box, Button, Divider, ListItemButton, Stack, SvgIcon, Tooltip } from '@mui/material';
import { Auth } from 'aws-amplify';
import { format } from 'date-fns';
import React, { useContext, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import AvatarName from "../common/avatarname/AvatarName";
import { PollContext } from '../common/context/PollContext';
import { UserContext } from '../common/context/UserContext';
import EligoSnackBar from '../common/EligoSnackBar';
import { AxiosEvotingInterceptor } from '../config/axios.interceptor';
import { RiAuctionFill } from "react-icons/ri";
import { BsBank2 } from 'react-icons/bs';
import { CgMenuGridO } from 'react-icons/cg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    topBar: {
        background: '#fff !important',
        boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036) !important'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    popOverIcon: {
        minWidth: '36px'
    },
    profile: {
        cursor: 'pointer',
        borderRadius: '25px',
        padding: '3px 5px 3px 15px',
        color: '#2a83ad',
        background: 'linear-gradient(135deg,rgba(16, 57, 150, 0.24) 0%, rgba(16, 57, 150, 0.09) 100%)',
        '&:hover': {
            background: 'linear-gradient(135deg, rgba(16, 57, 150, 0.09) 0%, rgba(16, 57, 150, 0.24) 100%)'
        }
    }
}));


const NavBar = () => {
    let history = useHistory();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const context = useContext(UserContext);
    const pollContext = useContext(PollContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const openMenuLink = Boolean(anchorElMenu);

    let changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'user-options-popover' : undefined;

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    let logout = async event => {
        event.preventDefault();
        setAnchorEl(null);
        setMessage({ showMsg: false, message: null, severity: null });
        try {
            // await Auth.signOut({ global: true }).then(res => {
            //     context.setUserSession({ isAuthenticated: false, session: null });
            //     pollContext.setPollDetails({ isAuthenticated: false, session: null })
            //     sessionStorage.clear();
            //     history.push("/");
            // })
            AxiosEvotingInterceptor.post('revoke', {token: sessionStorage.getItem('refreshToken')}).then(response => {
                    context.setUserSession({ isAuthenticated: false, session: null });
                    pollContext.setPollDetails({ isAuthenticated: false, session: null })
                    sessionStorage.clear();
                    history.push("/");
            }).catch((error) => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                } else {
                    setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' });
                }
            })
        } catch (error) {
            console.log('error', error);
        }
    }

    let dash = () => {
        history.push("/admin/dashboard")
    }

    const navigateToHome = () => {
        history.push("/polls")
    }

    const navigateToProfile = () => {
        history.push("/profile");
        handleClose();
    }

    const navigateToCustomer = () => {
        history.push("/clients");
        handleClose();
    }

    const handleMenuClick = (event) => {
        setAnchorElMenu(event.currentTarget);
    }

    const handleMenulinkClose = () => {
        setAnchorElMenu(null);
    }

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.topBar}>
                <Toolbar variant='regular'>
                    {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"> */}
                    <Tooltip title={t("Switch_To")} arrow>
                        <IconButton aria-label="menu" onClick={handleMenuClick} style={{ right: '10px', padding: '3px' }}>
                            <CgMenuGridO fontSize='30px' />
                        </IconButton>
                    </Tooltip>
                    <Popover
                        id='link-menu-popover'
                        open={openMenuLink}
                        anchorEl={anchorElMenu}
                        onClose={handleMenulinkClose}
                        PaperProps={{
                            style: { width: '20%' },
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <List component="nav" aria-label="switch">
                                    <ListItem>
                                        <ListItemText
                                            id="switch-to"
                                            primary={<Typography variant="body1" style={{ fontWeight: 'bold' }}>{t('Switch_To')}</Typography>}
                                        />
                                    </ListItem>
                                    <Link target="_blank" href={process.env.REACT_APP_AUCTION} style={{ textDecoration: 'none' }}>
                                        <ListItemButton key={1} id="link-to-auction">
                                            <img src={process.env.PUBLIC_URL + './images/auction.svg'} height={45} width={150} alt='TRUSTeAuctionLogo' style={{ padding: '3px 10px 3px 3px' }}></img>
                                            {/* {process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && <img src={process.env.PUBLIC_URL + './images/nbid.png'} height={38} width={92} alt='NbidLogo' style={{ padding: '3px 10px 3px 3px' }}></img>} */}
                                        </ListItemButton>
                                    </Link>
                                </List>
                            </Grid>
                            <Grid item xs={12}>
                                <List component="nav" aria-label="switch">
                                    <Link target="_blank" href={process.env.REACT_APP_ICMS} style={{ textDecoration: 'none', color: '#317590' }} >
                                        <ListItemButton key={1} id="link-to-icms">
                                            <ListItemText id="switch-to"
                                                primary={<Typography variant="h4" style={{ fontWeight: 'bold', marginLeft: '20px' }}>{t('ICMS')}</Typography>} />
                                        </ListItemButton>
                                    </Link>
                                </List>
                            </Grid>
                        </Grid>
                    </Popover>
                    <img id="navbar" src={process.env.PUBLIC_URL + '/evote-logo.svg'} style={{ width: isBrowser ? '12%' : '25%', cursor: 'pointer' }} alt=""
                        onClick={navigateToHome} />&nbsp;&nbsp;
                    {/* </IconButton> */}
                    {/* <IconButton>
                        <Menu color='primary' />
                    </IconButton> */}
                    {/* <Tooltip title={t("Navigate_ICMS_Platform")} arrow>
                    <Button size="small" variant="contained" color="primary" >
                        <Link
                            target="_blank"
                            href={process.env.REACT_APP_ICMS}
                            style={{ cursor: "pointer", color: "white", fontWeight: "bold", textDecoration: "none", display: "flex", gap: "8px", textTransform:'capitalize' }} >
                            <BsBank2 size="20px" />
                            ICMS
                        </Link>
                    </Button>
                </Tooltip>&nbsp;
                <Tooltip title={t("Navigate_Auction_Platform")} arrow>
                    <Button size="small" variant="contained" color="primary" >
                        <Link
                            target="_blank"
                            href={process.env.REACT_APP_AUCTION}
                            style={{ cursor: "pointer", color: "white", fontWeight: "bold", textDecoration: "none", display: "flex", alignItems: "center", gap: "8px", textTransform:'capitalize' }} >
                            <RiAuctionFill size='20px' />
                            e-auction
                        </Link>
                    </Button>
                </Tooltip> */}
                    <Typography id="navbartitle" variant="h6" className={classes.title} onClick={navigateToHome}
                        style={{ cursor: "pointer" }}>
                        {/* {t('App_Name')}
                        <Typography><span className='nav-bar-logo-font' style={{paddingBottom: '2rem'}}>eVoting</span></Typography> */}
                    </Typography>
                    {/* <button type="button" onClick={() => changeLanguage('en_CA')}>
                      EN_CA
                    </button>
                    <button type="button" onClick={() => changeLanguage('en_IN')}>
                      EN_IN
                    </button> */}
                    {context.userSession.last_login &&
                        <div style={{
                            color: 'rgba(90, 88, 88, 0.87)', fontWeight: 'bold',
                            fontSize: '0.8rem', marginRight: '18px'
                        }}>
                            <div>Last Login</div>
                            {format(new Date(context.userSession.last_login), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}
                        </div>
                    }
                    {/* {context.userSession.session != null && context.userSession.isAuthenticated &&
                        <h5 onClick={handleClick} className="pointer">{context.userSession.session.name}
                        <IconButton id="profilenav" aria-label="user profile"
                                className="nav_icons"
                                aria-haspopup="true"
                                color="inherit"
                                style={{padding: '0px'}}>
                            <ArrowDropDownIcon />
                        </IconButton>
                    </h5>} */}
                    {context.userSession.system_admin_of?.includes('e-voting') && <IconButton edge="end" id="settingicon"
                        className="nav_icons"
                        aria-label="account of current user"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={dash}>
                        <SettingsOutlinedIcon />
                    </IconButton>
                    }
                    {context.userSession.session != null && context.userSession.isAuthenticated &&
                        <Stack direction='row' spacing={0.3} className={classes.profile} onClick={handleClick} >
                            {/* <Stack direction='column' justifyContent='end'> */}
                                {/* <Typography color='textSecondary' style={{ fontSize: '0.65rem', fontWeight: 'bold', textAlign: 'start' }}>{t('WELCOME')}</Typography> */}
                                <Stack direction='row' alignItems='center' justifyContent='center'><Typography style={{ lineHeight: '1', fontSize: '1rem', fontWeight: 'bold' }}>{context.userSession.session.name}
                                </Typography><SvgIcon component={ArrowDropDownRounded} viewBox="6 7 13 13" style={{ height: '0.75em', color: 'rgb(0 0 0 / 44 %) !important' }} />
                                </Stack>
                            {/* </Stack> */}
                            {/* <Avatar sx={{ backgroundColor: '#67a1df' }} /> */}
                            <AvatarName />
                        </Stack>
                    }
                </Toolbar>
            </AppBar>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                {context.userSession.isAuthenticated && <Grid container>
                    <Grid item xs={12}>
                        <List component="nav" aria-label="addresses">
                            <ListItem>
                                <AvatarName />
                                <Box sx={{ my: 1.5, px: 2.5 }}>
                                    <Typography style={{fontWeight:'bold'}} variant="subtitle1" noWrap>
                                        {context.userSession.session.name}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                                        {context.userSession.session.email}
                                    </Typography>
                                </Box>
                            </ListItem>
                            <Divider />
                            <ListItem button key={1} onClick={navigateToProfile}>
                                <ListItemIcon id='navbaricon' className={classes.popOverIcon}>
                                    <PersonIcon id="personicon" />
                                </ListItemIcon>
                                <ListItemText id="navbarprofile" primary={t('My_Profile')}></ListItemText>
                            </ListItem>
                            <ListItem button key={2} onClick={navigateToCustomer}>
                                <ListItemIcon id="navbarpeople" className={classes.popOverIcon}>
                                    <PeopleIcon id="peopleIcon" />
                                </ListItemIcon>
                                <ListItemText id="myCustomer" primary={t('My_Customers')}></ListItemText>
                            </ListItem>
                            <ListItem button key={3} onClick={logout}>
                                <ListItemIcon className={classes.popOverIcon}>
                                    <ExitToApp id="signout" />
                                </ListItemIcon>
                                <ListItemText id="navbarsignout" primary={t('Sign_Out')}></ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>}
            </Popover>
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message} severity={message.severity} />}
        </div>
    );
}

export default NavBar;
